@import url('https://fonts.googleapis.com/css2?family=Chewy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;500;600&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Glass+Antiqua&display=swap');

@font-face {
    font-family: 'Barlow';
    src: url(./Assets/fonts/barlow-semi-condensed.regular.ttf);
  }
@font-face {
    font-family: 'Glass';
    src: url(./Assets/fonts/GlassAntiqua-Regular.ttf);
  }

@font-face {
    font-family: 'Moontime';
    src: url(./Assets/fonts/FontsFree-Net-MoonTime-Regular-1.ttf);
  }

@font-face {
    font-family: 'Playlist';
    src: url(./Assets/fonts/Playlist\ Script.otf);
  }

@font-face {
    font-family: 'Crossword';
    src: url(./Assets/fonts/CWBTRIAL.ttf);
  }

@font-face {
    font-family: 'Times';
    src: url(./Assets/fonts/times.ttf);
  
  }

  @font-face {
    font-family: 'Timesr';
    src: url(./Assets/fonts/TIMESR.ttf);
  }

  body{
    overflow-x: hidden;
    background: #f9f4f0;
    cursor: url(./Assets/images/flying.gif);
  }

  .stroke-text{
    color: #FF9E5E;
    -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #FF9E5E;
  }

  .active {
    background: url(./Assets/images/menuActive.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 10px;
  }

  .charac-active {
    background: url(./Assets/images/charac-active.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 10px;
  }

  .menu li {
    padding: 10px;
    display:flex;
    align-items: center
  }

  .title{
    font-size: 26px;
    font-weight: semibold;
    font-family: 'Crossword';
    padding: 0px;
    margin: 0px;
  }

  .sub-title {
    font-size: 16px;
    font-family: 'Crossword'
  }

  .menu {
    font-size: 20px;
    font-family: 'Crossword'
  }

  .button {
    background: #876ef1;
  }

  .paragraph {
       font-family: Times; 
       font-size: 20px
    }

    .characters {
        background: url(./Assets/images/bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -10;
    }


    .learning{
        background: url(./Assets/images/bg-gray.png);
        background-size: cover;

    }

    .buynow{
      background: url(./Assets/images/buynowbg.png);
      background-size: cover;
    }

    @media screen and (max-width: 460px){
      .link{
        display: none;
      }
      
      .buynow{
      background: url(./Assets/images/buynowbgmobile.png);
      background-size: 100% 100%;
      background-repeat: no-repeat
      
    }

    .characters{
      background: url(./Assets/images/bgMobile.png);
        background-size: cover;
    }

    .learning{
      background: url(./Assets/images/bg-gray-mob.png);
      background-size: cover;

  }

    .learning-title{
      background: url(./Assets/images/greenblob.png);
      background-repeat: no-repeat;
      background-size: 80px 80px;
      background-position: 100px 0px;
      padding: 14px
     }

     .share-title{
      background: url(./Assets/images/share_blob.png);
      background-repeat: no-repeat;
      background-size: 80px 80px;
      background-position: 100px 0px;
      padding: 14px
    }

     .coming-title{
      background: url(./Assets/images/authorblob.png);
      background-repeat: no-repeat;
      background-size: 80px 80px;
      background-position: 100px 0px;
      padding: 14px
    }

     .charac-title{
      background: url(./Assets/images/charac_blob.png);
      background-repeat: no-repeat;
      background-size: 80px 80px;
      background-position: 100px 0px;
      padding: 14px
     }

    }

    .slick-arrow{
      display: none !important;
    }

    .review-box .slick-arrow{
      display: block !important;
    }
    .review-box .slick-prev:before {
      content: url(./Assets/images/left-arrow.png);
      margin-left: -18px;
    }
    .review-box .slick-next:before {
      content: url(./Assets/images/right-arrow.png);
      margin-right: -18px;
    }
    .review-box .slick-list {
      margin: 0 -16px !important;
    }
    
    .review-box .slick-slide > div {
      padding: 0 16px !important;
    }
    .author{
      background: url(./Assets/images/authorbg.png);
        background-size: cover;
    }

    .icon {
      width: 50px;
      height: 50px;
      background: black;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #f9f4f0;
      border-radius: 50px;
    }


    .input {
      background: url(./Assets/images/input.png);
      background-size: 100% 100%;
      background-repeat: no-repeat
      
    }

    .textarea {
      background: url(./Assets/images/textarea.png);
      background-size: 100% 100%;
      background-repeat: no-repeat
      
    }

    .footer{
      background: url(./Assets/images/footerbg.png);
        background-size: cover;
    }

    .series-title{
      background: url(./Assets/images/series_blob.png);
      background-repeat: no-repeat;
      background-size: 80px 80px;
      background-position: 0px 0px;
      height: 80px;
      padding: 50px;
    }

    .author-title{
      background: url(./Assets/images/authorblob.png);
      background-repeat: no-repeat;
      background-size: 80px 80px;
      background-position: 0px 0px;
      height: 80px;
    }

    .buy-title{
      background: url(./Assets/images/buyBookBlob.png);
      background-repeat: no-repeat;
      background-size: 80px 80px;
      background-position: 0px 0px;
      padding: 14px
     }

     .learning-title{
      background: url(./Assets/images/greenblob.png);
      background-repeat: no-repeat;
      background-size: 80px 80px;
      background-position: 0px 0px;
      padding: 14px
     }

     .coming-title{
      background: url(./Assets/images/authorblob.png);
      background-repeat: no-repeat;
      background-size: 80px 80px;
      background-position: 0px 0px;
      padding: 14px
    }

    .share-title{
      background: url(./Assets/images/share_blob.png);
      background-repeat: no-repeat;
      background-size: 80px 80px;
      background-position: 0px 0px;
      padding: 14px
    }

     .charac-title{
      background: url(./Assets/images/charac_blob.png);
      background-repeat: no-repeat;
      background-size: 80px 80px;
      background-position: 0px 0px;
      padding: 14px
     }
