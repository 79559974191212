@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .cloud {
      @apply w-20 sm:w-24 lg:w-24 2xl:w-[180px] h-12 sm:h-14 lg:h-16 2xl:h-[100px];
    }
  }

  
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translate3d(20%, 0, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .fadeInRight {
    animation-name: fadeInRight;
  }

  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translate3d(-20%, 0, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .fadeInLeft {
    animation-name: fadeInLeft;
  }